<script
    lang="ts"
    setup
>
    type Props = {
        disabled?: boolean
        active?: boolean
        inactiveColor?: string
    }

    const props = withDefaults(defineProps<Props>(), {
        disabled: false,
        active: false,
        inactiveColor: '#000'
    })

    const containerStyle = computed<string>(() => {
        return `
            --dropdown-item-inactive-color:${ props.inactiveColor };
            --dropdown-item-active-color:#000;
            --dropdown-item-disable-color:#a7a7a7;
        `
    })

    const containerClass = computed<string>(() => {
        let classes = `
            flex
            items-center
            w-full
            min-h-[36px]
            p-2
            text-[14px]
            text-[var(--dropdown-item-inactive-color)]
            font-regular
            leading-[130%]
            rounded-[8px]
            cursor-pointer
            select-none
        `

        if (props.disabled) {
            classes += ' text-[var(--dropdown-item-disable-color)] cursor-not-allowed'
        } else if (props.active) {
            classes += ' bg-[#f6f7f8] text-[var(--dropdown-item-active-color)]'
        } else {
            classes += ' hover:bg-[#f6f7f8] hover:text-[var(--dropdown-item-active-color)]'
        }

        return classes
    })
</script>

<template>
    <div
        :data-active="props.active || undefined"
        :class="containerClass"
        :style="containerStyle"
    >
        <slot/>
    </div>
</template>
